import React from 'react'
import loading from '../assest/loading.png'

function Loader () {
    return (
        <div className="loader">
            <img src={loading} style={{ maxWidth: '17rem' }} alt="" />
            {/* <div class="divLoader">
                <div></div>
            </div> */}
        </div>
    )
}

export default Loader
